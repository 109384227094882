import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HeroImage from "../../components/HeroImage"
import AgnedaPage from "../../components/AgendaPage"

const Agenda = ({ pageContext }) => {
  const qaQuery = useStaticQuery(
    graphql`
      query qaQuery {
        allStrapiSession(
          filter: {
            event: { id: { eq: 1 } }
            session_type: { elemMatch: { id: { eq: 4 } } }
          }
        ) {
          nodes {
            sectionPage: gatsbyPath(
              filePath: "/agenda/qa/{StrapiSession.strapiId}"
            )
            id
            start_time
            stream
            speakers {
              avatar {
                childImageSharp {
                  resize(width: 320) {
                    src
                    width
                    height
                    aspectRatio
                  }
                }
              }
              name {
                name
                origional_name
              }
              job {
                corp
                id
                job_title
              }
            }
            location
            strapiId
            end_time
            session_type {
              id
              name
            }
            name
            tag {
              tag
            }
          }
        }
        allStrapiWriting(filter: { key: { eq: "agenda_header" } }) {
          nodes {
            key
            writing
          }
        }
      }
    `
  )
  const writing = qaQuery.allStrapiWriting.nodes[0].writing
  const session = qaQuery.allStrapiSession.nodes
    .sort((a, b) => {
      return new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
    })

  return (
    <Layout>
      <SEO title={`QA議程`} />
      <HeroImage
        writing={writing || ""}
        portrait="/images/hero.agenda.portrait.svg"
        landscape="/images/hero.agenda.landscape.svg"
      />
      <AgnedaPage session={session} qa={true} />
    </Layout>
  )
}

export default Agenda
